import styled from 'styled-components'

interface ContainerProps {
  opened?: boolean
  width?: string
  color?: string
  bgColor?: string
  border?: string
  fontFamily?: string
  padding?: string
}

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;

  @media (max-width: 980px) {
    max-width: 100%;
  }
`

export const Content = styled.div<ContainerProps>`
  max-width: 503px;
  height: 100%;
  box-sizing: border-box;
  border-right: ${(props) => (props.border ? props.border : 'none')};
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'rgb(249, 249, 251)'};
  margin: 134px 40px 40px 40px;

  @media (max-width: 980px) {
    margin: 40px auto;
    width: 85%;
  }
`

export const ContentCentered = styled.div<ContainerProps>`
  height: 100%;
  box-sizing: border-box;
  border-right: ${(props) => (props.border ? props.border : 'none')};
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'rgb(249, 249, 251)'};
  margin: 134px 0px 40px 0px;

  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 980px) {
    margin: 40px auto;
    width: 85%;
  }
`

export const AbsoluteStatusBadge = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 543px;
  z-index: 3;
`

export const FormSection = styled.div`
  box-sizing: border-box;
  padding: 24px 32px 32px;
  border: 1px solid #e9e7ee;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  max-width: 503px;
  width: 100%;
  position: relative;
  gap: 12px;
  background: #fff;

  h3 {
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    margin: 40px 0 0 0;
    padding: 0;
  }
`

export const ContentSection = styled.div`
  box-sizing: border-box;
  padding: 48px;
  border: 1px solid #e9e7ee;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  max-width: 503px;
  width: 100%;
  position: relative;
  gap: 12px;
  background: #fff;
  align-items: center;

  h3 {
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    margin: 10px 0 0 0;
    padding: 0;
    color: #34353a;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #34353a;
    text-align: center;
  }
`

interface FormSectionOptionalProps {
  visible: boolean
}

export const FormSectionOptional = styled.div<FormSectionOptionalProps>`
  box-sizing: border-box;
  padding: 24px 32px 32px;
  border: 1px solid #e9e7ee;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-bottom: 40px;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  max-width: 503px;
  width: 100%;
  position: relative;
  gap: 12px;
  background: #fff;
`

interface Button {
  margin?: string
  bgColor?: string
  padding?: string
  borderRadius?: string
  width?: string
  height?: string
  border?: string
  boxShadow?: string
  boxShadowFocus?: string
  color?: string
  colorHover?: string
  bgColorHover?: string
  borderHover?: string
  bgColorDisabled?: string
  borderDisabled?: string
  colorDisabled?: string
  fontSize?: string
  lineHeight?: string
  fontWeight?: string
  fontFamily?: string
}

export const ProceedButton = styled.button<Button>`
  border: none;
  outline: none;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#0071f3')};
  color: ${(props) => (props.color ? props.color : '#fff')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '18px')};
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : 'Inter')};
  padding: ${(props) => (props.padding ? props.padding : '10px 44.5px')};
  text-align: center;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '100px'};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  @media (max-width: 980px) {
    width: 100%;
  }

  &:hover {
    background-color: ${(props) =>
      props.bgColorHover ? props.bgColorHover : '#4298fb'};
    cursor: pointer;
  }

  &:focus {
    box-shadow: ${(props) =>
      props.boxShadowFocus
        ? props.boxShadowFocus
        : '0px 0px 0px 3px rgba(0, 113, 243, 0.3)'};
  }

  &:disabled {
    background-color: ${(props) =>
      props.bgColorDisabled ? props.bgColorDisabled : '#acb0b5'};
    cursor: auto;
  }
`

export const Button = styled.button<Button>`
  display: flex;
  padding: 10px 24px;
  min-width: 120px;
  justify-content: center;
  align-items: center;
  border: 2px solid #cecbd6;
  background: ${(props) => (props.bgColor ? props.bgColor : '#fff')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '100px'};
  color: ${(props) => (props.color ? props.color : '#0071f3')};
  font-weight: 500;
  line-height: 20px;
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};

  cursor: pointer;
  transition: background 0.3s ease-in-out;
  &:hover {
    background: ${(props) =>
      props.bgColorHover ? props.bgColorHover : '#f8f7fa'};
  }
`

export const InputRadio = styled.input``

export const Boxed = styled.div``

export const AddMore = styled.div`
  box-sizing: border-box;
  padding: 24px 32px 32px;
  border: 1px solid #e9e7ee;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  max-width: 503px;
  background: #fff;

  h3 {
    font-family: 'Inter';
    font-size: 18px;
    color: #2b253a;
    font-weight: 600;
    padding: 0;
    margin: 0 0 8px 0;
  }

  span {
    color: #565b60;
    font-size: 14px;
    margin-bottom: 24px;
  }

  @media (max-width: 980px) {
    max-width: 100%;
  }
`

export const UbosContainer = styled.div`
  width: 100%;
  max-width: 503px;

  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #e9e7ee;
  background: #fff;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.05);

  display: flex;
  padding: 24px 32px;
  flex-direction: column;
  gap: 16px;

  h5 {
    margin: 0;
    color: #2b253a;
    /* Headline 5 */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
  }

  p {
    margin: 0;
    color: #565b60;
    /* Paragraph 4 - Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  @media (max-width: 980px) {
    max-width: 100%;
  }
`

export const AddNewUbosContainer = styled(UbosContainer)`
  align-items: flex-start;
  margin: 40px 0;
`

export const PollingLoadingBox = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 250px;

  color: var(--Dark-grey, #34353a);
  text-align: center;

  /* Paragraph 5 - Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
`

export const InfoPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  gap: 20px;

  h1,
  main h1 {
    margin: 0;
    color: var(--Text-text-header-primary, #2b253a);
    font-family: Inter;
    font-size: var(--Fonts-Size-Headline-4, 24px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Fonts-Line-height-Headline-4, 36px); /* 150% */
  }

  h2 {
    margin: 0;
    color: var(--Text-text-secondary, #565b60);
    font-family: Inter;
    font-size: var(--Fonts-Size-Paragraph-3, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--Fonts-Line-height-Paragraph-3, 24px); /* 150% */
    letter-spacing: -0.16px;
  }

  main {
    border-radius: 8px;
    border: 1px solid var(--Stroke-card, #e6ddf9);
    background: #fff;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    text-align: center;
  }
`

export const Circle = styled.div<{ margin?: string }>`
  width: 120px;
  height: 120px;
  border-radius: var(--Radius-round, 100px);
  background: var(--Background-bg-secondary, #f7f9fc);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => (props.margin ? props.margin : '30px 0')};
`
