import React, { useState, useEffect, useContext } from 'react'
import {
  Category,
  CategoryName,
  CategoryOrbWrap,
  CategoryOrb,
  CategoryWrap,
  Container,
  Step,
  StepName,
  StepOrb,
  Steps,
  StepsDivider,
  Current,
} from './OnboardingWizard.styles'
import { observer } from 'mobx-react'
import { StoreContext } from '../../components/App'

interface WizardItem {
  name: string
  status: 'active' | 'done' | '' | string
  id?: string
  steps?: WizardItem[]
}

interface OnboardingWizardProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  data: WizardItem[]
  changeStep?: (id: string) => void
  upperCase?: boolean
  clickable?: boolean
  colorCurrent?: string
  fontFamily?: string
  // CATEGORY
  colorCategoryName?: string
  colorCategoryNameActive?: string
  colorCategoryNameDone?: string
  // CATEGORY ORB
  colorCategoryOrb?: string
  borderColorCategoryOrb?: string
  bgColorCategoryOrb?: string
  // CATEGORY ORB ACTIVE
  colorCategoryOrbActive?: string
  borderColorCategoryOrbActive?: string
  bgColorCategoryOrbActive?: string
  // CATEGORY ORB DONE
  colorCategoryOrbDone?: string
  borderColorCategoryOrbDone?: string
  bgColorCategoryOrbDone?: string
  // DIVIDER
  bgColorDivider?: string
  borderColorDivider?: string
  bgColorDividerDone?: string
  // STEP
  colorStepName?: string
  colorStepNameActive?: string
  colorStepNameDone?: string
  // STEP ORB
  colorStepOrb?: string
  borderColorStepOrb?: string
  bgColorStepOrb?: string
  // STEP ORB ACTIVE
  colorStepOrbActive?: string
  borderColorStepOrbActive?: string
  bgColorStepOrbActive?: string
  // STEP ORB DONE
  colorStepOrbDone?: string
  borderColorStepOrbDone?: string
  bgColorStepOrbDone?: string
}

export const OnboardingWizard = observer(
  ({
    data,
    changeStep,
    upperCase,
    clickable,
    colorCurrent,
    fontFamily,
    // CATEGORY
    colorCategoryName,
    colorCategoryNameActive,
    colorCategoryNameDone,
    // CATEGORY ORB
    colorCategoryOrb,
    borderColorCategoryOrb,
    bgColorCategoryOrb,
    // CATEGORY ORB ACTIVE
    colorCategoryOrbActive,
    borderColorCategoryOrbActive,
    bgColorCategoryOrbActive,
    // CATEGORY ORB DONE
    colorCategoryOrbDone,
    borderColorCategoryOrbDone,
    bgColorCategoryOrbDone,
    // DIVIDER
    bgColorDivider,
    borderColorDivider,
    bgColorDividerDone,
    // STEP
    colorStepName,
    colorStepNameActive,
    colorStepNameDone,
    // STEP ORB
    colorStepOrb,
    borderColorStepOrb,
    bgColorStepOrb,
    // STEP ORB ACTIVE
    colorStepOrbActive,
    borderColorStepOrbActive,
    bgColorStepOrbActive,
    // STEP ORB DONE
    colorStepOrbDone,
    borderColorStepOrbDone,
    bgColorStepOrbDone,
  }: OnboardingWizardProps) => {
    const store = useContext(StoreContext)
    const { translations } = store.TranslationsState
    const [currentData, setCurrentData] = useState([])

    useEffect(() => {
      setCurrentData(data)
    }, [data])

    const commonStyles = {
      upperCase,
      clickable,
      colorCurrent,
      fontFamily,
      // CATEGORY
      colorCategoryName,
      colorCategoryNameActive,
      colorCategoryNameDone,
      // CATEGORY ORB
      colorCategoryOrb,
      borderColorCategoryOrb,
      bgColorCategoryOrb,
      // CATEGORY ORB ACTIVE
      colorCategoryOrbActive,
      borderColorCategoryOrbActive,
      bgColorCategoryOrbActive,
      // CATEGORY ORB DONE
      colorCategoryOrbDone,
      borderColorCategoryOrbDone,
      bgColorCategoryOrbDone,
      // DIVIDER
      bgColorDivider,
      borderColorDivider,
      bgColorDividerDone,
      // STEP
      colorStepName,
      colorStepNameActive,
      colorStepNameDone,
      // STEP ORB
      colorStepOrb,
      borderColorStepOrb,
      bgColorStepOrb,
      // STEP ORB ACTIVE
      colorStepOrbActive,
      borderColorStepOrbActive,
      bgColorStepOrbActive,
      // STEP ORB DONE
      colorStepOrbDone,
      borderColorStepOrbDone,
      bgColorStepOrbDone,
    }

    const getName = (name: string) => {
      const nameSplit = name.split('.')
      let transResolved = translations[nameSplit.shift()]

      nameSplit.forEach((split) => {
        transResolved = transResolved[split]
      })

      return transResolved || name
    }

    const handleChangeStep = (category) => {
      category.nav && category.status && category.id
        ? changeStep(category.id)
        : undefined
    }

    return (
      <Container>
        {currentData.map((category, index) => {
          const isCurrent =
            category.status === 'active' &&
            ((category.steps &&
              !category.steps.find((step) => step.status === 'active')) ||
              !category.steps)

          return (
            <CategoryWrap key={category.name}>
              <Category>
                {isCurrent && <Current isCategory={true} />}
                <CategoryOrbWrap>
                  <CategoryOrb
                    status={category.status}
                    {...commonStyles}
                    onClick={() => handleChangeStep(category)}
                    clickable={category.status && category.id}
                  >
                    {index + 1}
                  </CategoryOrb>
                </CategoryOrbWrap>
                <CategoryName
                  isCurrent={isCurrent}
                  status={category.status}
                  {...commonStyles}
                  onClick={() => handleChangeStep(category)}
                  clickable={category.status && category.id}
                >
                  {upperCase
                    ? getName(category.name).toUpperCase()
                    : getName(category.name)}
                </CategoryName>
              </Category>
              {category.steps && category.steps.length ? (
                <Steps>
                  <StepsDivider status={category.status} {...commonStyles} />
                  {category.steps.map((step, stepIndex) => {
                    const isCurrentStep = step.status === 'active'
                    return (
                      <div key={step.name}>
                        <Step>
                          {isCurrentStep && <Current />}
                          <StepOrb
                            status={step.status}
                            {...commonStyles}
                            onClick={() => handleChangeStep(step)}
                            clickable={step.status && step.id}
                          />
                          <StepName
                            isCurrent={isCurrentStep}
                            status={step.status}
                            {...commonStyles}
                            onClick={() => handleChangeStep(step)}
                            clickable={step.status && step.id}
                          >
                            {getName(step.name)}
                          </StepName>
                        </Step>
                        {stepIndex + 1 !== category.steps.length ? (
                          <StepsDivider
                            status={category.steps[stepIndex + 1].status}
                            {...commonStyles}
                          />
                        ) : (
                          <StepsDivider
                            status={currentData[index + 1].status}
                            {...commonStyles}
                          />
                        )}
                      </div>
                    )
                  })}
                </Steps>
              ) : (
                index + 1 !== currentData.length && <StepsDivider />
              )}
            </CategoryWrap>
          )
        })}
      </Container>
    )
  }
)
