import styled from 'styled-components'

export interface ContainerProps {
  margin?: string
  marginMobile?: string
  fontFamily?: string
  height?: string
  border?: string
  bgColor?: string
  position?: string
  zIndex?: string
}

export interface HeaderProps {
  headerFontSize?: string
  headerLineHeight?: string
  headerColor?: string
  headerMargin?: string
  headerPadding?: string
  headerFontWeight?: string
}

export interface SubtitleProps {
  subtitleFontSize?: string
  subtitleLineHeight?: string
  subtitleColor?: string
}

export const Container = styled.div<ContainerProps>`
  width: calc(100% - 300px);
  display: flex;
  box-sizing: border-box;
  font-family: ${(props) => props.fontFamily || ''};
  border-bottom: ${(props) =>
    props.border ? props.border : '1px solid rgb(234, 227, 248)'};
  height: ${(props) => (props.height ? props.height : '94px')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#fff')};
  position: ${(props) => (props.position ? props.position : 'fixed')};
  top: 0;
  z-index: ${(props) => (props.zIndex ? props.zIndex : 3)};
  @media (max-width: 980px) {
    width: 100%;
  }
`

export const Headings = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${(props) => (props.margin ? props.margin : '40px 0 32px')};
  @media (max-width: 980px) {
    margin: ${(props) =>
      props.marginMobile ? props.marginMobile : '40px 0 32px'};
  }
`

export const Toolbox = styled.div``

export const Header = styled.h1<HeaderProps>`
  font-size: ${(props) =>
    props.headerFontSize ? props.headerFontSize : '18px'};
  line-height: ${(props) =>
    props.headerLineHeight ? props.headerLineHeight : '26px'};
  color: ${(props) => (props.headerColor ? props.headerColor : '#272142')};
  margin: ${(props) => (props.headerMargin ? props.headerMargin : '0 0 4px 0')};
  padding: ${(props) => (props.headerPadding ? props.headerPadding : '0')};
  font-weight: ${(props) =>
    props.headerFontWeight ? props.headerFontWeight : '600'};
`

export const Subtitle = styled.span<SubtitleProps>`
  width: 80%;
  font-size: ${(props) =>
    props.subtitleFontSize ? props.subtitleFontSize : '14px'};
  line-height: ${(props) =>
    props.subtitleLineHeight ? props.subtitleLineHeight : '20px'};
  color: ${(props) => (props.subtitleColor ? props.subtitleColor : '#767676')};
`
