import React, { FC, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { observer } from 'mobx-react'
import { StoreContext } from '../components/App'

export const CustomHelmet: FC = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const { pageTitle: trans } = store.TranslationsState.translations
  return (
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/assets/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/assets/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/assets/favicon-16x16.png"
      />
      <meta name="description" content="Onboarding Web" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800&display=swap"
        rel="stylesheet"
      />
      <title>
        {theme.titlePrefix}
        {trans}
      </title>
    </Helmet>
  )
})
