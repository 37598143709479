import {
  Actor,
  blankActor,
  Request,
} from '../pages/EnrollCompany/subpages/Actor.interface'

export const addPersonFunction = (
  newId: number,
  isDirector: boolean,
  isUbo: boolean,
  people: Actor[],
  saveRequest: Request
) => {
  const newActor = {
    ...blankActor,
    actorId: `new${newId++}`,
    isDirector: isDirector,
    isUbo: isUbo,
    isNew: true,
  }

  return {
    people: [...people, newActor],
    saveRequest: {
      ...saveRequest,
      add: [...saveRequest.add, newActor],
    },
  }
}

export const detelePersonFunction = (
  id: string,
  people: Actor[],
  saveRequest: Request
) => {
  return {
    people: people.filter((person) => person.actorId !== id),
    saveRequest: {
      ...saveRequest,
      add: saveRequest.add.filter((person) => person.actorId !== id),
    },
  }
}

export const changePersonFunction = (
  actor: Actor,
  people: Actor[],
  saveRequest: Request
) => {
  const previouslyEdited = saveRequest.edit.findIndex(
    (person) => person.actorId === actor.actorId
  )

  const previouslyAdded = saveRequest.add.findIndex(
    (person) => person.actorId === actor.actorId
  )

  return {
    people: people.map((person) =>
      person.actorId === actor.actorId ? actor : person
    ),
    saveRequest:
      previouslyAdded > -1
        ? {
            ...saveRequest,
            add: saveRequest.add.map((person) =>
              person.actorId === actor.actorId ? actor : person
            ),
          }
        : {
            ...saveRequest,
            edit:
              previouslyEdited > -1
                ? saveRequest.edit.map((person) =>
                    person.actorId === actor.actorId ? actor : person
                  )
                : [...saveRequest.edit, actor],
          },
  }
}

export const addAssignFunction = (
  ids: Record<string, boolean>,
  initialIds: Record<string, boolean>,
  saveRequest: Request
) => {
  return {
    ...saveRequest,
    assign: Object.keys(initialIds).filter(
      (key) => key in ids && ids[key] && initialIds[key] !== ids[key]
    ),
    unassign: Object.keys(initialIds).filter(
      (key) => key in ids && !ids[key] && initialIds[key] !== ids[key]
    ),
  }
}
