import React, { FC } from 'react'
import { StyledCascader } from './GicsSelect.styles'
import { gicsDataSet } from '../../constants/gicsSorted'

export interface GicsSelectItem {
  value?: string
  label?: string
  indentCount?: number
}

export type GicsSelectValue = Array<string>

interface GicsSelectProps {
  name?: string
  value?: GicsSelectValue
  onChange?: (newValue: GicsSelectValue) => void
  disabled?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trans?: any
  borderRadius?: string
  border?: string
  color?: string
  borderColorFocus?: string
}

export const GicsSelect: FC<GicsSelectProps> = ({
  value,
  onChange,
  disabled,
  trans,
  borderRadius,
  border,
  color,
  borderColorFocus,
}) => {
  const options = gicsDataSet(trans).options

  return (
    <StyledCascader
      suffixIcon={
        <svg
          height="20"
          width="20"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
        >
          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg>
      }
      size="large"
      className="gics-select"
      options={options}
      value={value}
      onChange={onChange}
      disabled={disabled}
      changeOnSelect
      {...(borderRadius && { borderRadius: borderRadius })}
      border={border}
      color={color}
      {...(borderColorFocus && { borderColorFocus: borderColorFocus })}
    />
  )
}
