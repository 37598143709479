export interface ActorInfo {
  firstName: string
  infix: string
  lastName: string
  email?: string
  phoneNumber?: string
  dateOfBirth?: number
}
export interface Actor {
  actorId: string
  info?: ActorInfo | undefined
  address?:
    | {
        line1: string
        line2: string
        city: string
        postcode: string
        country: string
      }
    | undefined
  providerInfo?: {
    name: string
    dateOfBirth?: number
    address?: string
  }
  isDirectorFromProvider?: boolean
  isUboFromProvider?: boolean
  isApplicant?: boolean
  isDirector?: boolean
  isUbo?: boolean
  isIdentityVerificationStarted?: boolean
  isAddressVerifciaitonStarted?: boolean
  lastEmailSent?: number
  successfulVerification?: number
  isVerified?: boolean
  isEmailSent?: boolean
  isNew?: boolean
}

export const blankActor: Actor = {
  actorId: '',
  info: {
    firstName: '',
    infix: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dateOfBirth: 0,
  },
}

export interface Request {
  add: Actor[]
  edit: Actor[]
  assign: string[]
  unassign: string[]
}

export const blankRequest: Request = {
  add: [],
  edit: [],
  assign: [],
  unassign: [],
}
