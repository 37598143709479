import { Actor } from '../pages/EnrollCompany/subpages/Actor.interface'

export function formatDate(timestamp: number): string {
  const date = new Date(timestamp)

  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return `${year}-${month}-${day}`
}

export function getFormattedDate(person: Actor) {
  if (person.info && person.info.dateOfBirth) {
    return formatDate(person.info.dateOfBirth)
  } else if (person.providerInfo) {
    return formatDate(person.providerInfo.dateOfBirth)
  } else {
    return ''
  }
}

export function getActorsDisplayName(person: Actor) {
  const tempName =
    person?.info &&
    (
      person.info.firstName +
      ' ' +
      person.info.infix +
      ' ' +
      person.info.lastName
    )
      .replace(/  +/g, ' ')
      .trim()

  return tempName || person?.providerInfo?.name || ''
}
