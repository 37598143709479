import axios from 'axios'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'

import { StoreContext } from '../../../components/App'
import { Container, Content, ContentSection } from './enroll.styles'
import { PageHeading } from '../../../components/PageHeading/PageHeading'
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner'

export const AddressVerificationPolling = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.AppState

  const {
    enrollCompanyPage: { addressVerificationPolling: trans },
  } = store.TranslationsState.translations

  const [isPolling, setIsPolling] = useState(true)

  const pollVerificationData = async () => {
    try {
      const res = await axios.get<{
        redirectUrl?: string
      }>(`${process.env.WEB_API_URL}/merchant-enrolment/should-continue`, {
        withCredentials: true,
      })

      if (res.data.redirectUrl) {
        setIsPolling(false)
        window.location.href = res.data.redirectUrl
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isPolling) {
      const intervalId = setInterval(pollVerificationData, 3000) // Poll every 3 seconds

      // Cleanup on unmount or when stopping polling
      return () => clearInterval(intervalId)
    }
  }, [isPolling])

  return (
    <Container>
      <PageHeading margin="0 0 0 40px" marginMobile="0 0 0 80px" />
      <Content>
        <ContentSection>
          <LoadingSpinner
            {...theme.loadingSpinner}
            height="100px"
            width="48px"
            bgColor="#fff"
            primary="#0071f3"
          />
          <h3>{trans.title}</h3>
          <p>
            {trans.description.split('.').map((item, key) => {
              return (
                <div key={key}>
                  {item}
                  {key === 0 && '.'}
                </div>
              )
            })}
          </p>
        </ContentSection>
      </Content>
    </Container>
  )
})
