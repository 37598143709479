import styled from 'styled-components'

export const GoBack = styled.button`
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  text-align: left;
  display: flex;
  line-height: 16px;
  margin-top: 40px;
  cursor: pointer;
`

export const Container = styled.div`
  width: 100%;
  max-width: 503px;
  display: flex;
  flex-direction: column;
  margin: 40px 0 0 0;
  background: #fff;

  @media (max-width: 980px) {
    margin: 80px 0 0 0;
    max-width: 100%;
  }
`

export const Header = styled.h1`
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 4px 0;
`

export const SubHeader = styled.span`
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  color: #565b60;
  margin: 0;
`

export const Splash = styled.img`
  width: 100%;
  max-width: 70px;
  margin: 20px auto;

  @media (max-width: 980px) {
    margin: 20px auto;
  }
`

export const SplashCenter = styled.div`
  display: flex;
  justify-content: center;
`

export const Button = styled.button`
  outline: none;
  border: none;
  background-color: #0071f3;
  color: white;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  border-radius: 100px;
  text-align: center;
  padding: 10px 44.5px;
  width: fit-content;
  cursor: pointer;
  @media (max-width: 980px) {
    width: 100%;
  }

  &:hover {
    background-color: #4298fb;
  }

  &:disabled {
    background-color: #d9d9d9;
  }
`

export const ToRight = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: right;

  @media (max-width: 480px) {
    justify-content: center;

    a {
      width: 100%;
    }
  }
`

export const PanelList = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const Panel = styled.li`
  width: 100%;
  box-sizing: border-box;
  background: white;
  border-radius: 8px;
  border: 1px solid #e9e7ee;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  margin-bottom: 15px;
  padding: 15px;

  &:last-child {
    margin-bottom: 0px;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid #b9ccfd;
    background-color: #f7f9fc;
  }

  &.current {
    border: 1px solid #0071f3;
    box-shadow: 0px 0px 0px 3px rgba(0, 113, 243, 0.3);
  }

  &.disabled {
    background: #f6f6f6;
    color: #707070;
  }
`

export const PanelThumbnail = styled.img`
  width: 50px;
  height: 50px;
  font-size: 12px;
`

export const PanelContent = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin: 0 0 4px 0;
  }

  span {
    color: #565b60;
    font-size: 14px;
  }
`

export const PanelNavigation = styled.img`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  font-size: 12px;
  cursor: pointer;
`

export const PanelInfo = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
`

export const HeaderNew = styled.h1`
  color: var(--Dark-grey, #34353a);
  text-align: center;

  /* Headline 4 */
  font-family: Inter;
  font-size: var(--Fonts-Size-Headline-4, 24px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Fonts-Line-height-Headline-4, 36px); /* 150% */

  margin: 10px 0 0 0;
`

export const SubHeaderNew = styled.span`
  display: block;
  color: var(--Dark-grey, #34353a);
  text-align: center;

  /* Paragraph 3 - Regular */
  font-family: Inter;
  font-size: var(--Fonts-Size-Paragraph-3, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Fonts-Line-height-Paragraph-3, 24px); /* 150% */
  letter-spacing: -0.16px;

  margin: 0 0 20px 0;
`
