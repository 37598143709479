import styled from 'styled-components'

export const Container = styled.div`
  box-sizing: border-box;
  padding: 24px 32px 32px;
  border: 1px solid #e9e7ee;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-width: 503px;
  width: 100%;
  position: relative;
  background: #fff;

  h3 {
    font-family: 'Inter';
    font-size: 18px;
    color: #2b253a;
    font-weight: 600;
    padding: 0;
    margin: 0 0 16px 0;
  }

  button.edit {
    position: absolute;
    top: 24px;
    right: 32px;
    padding: 0;
    border: 0;
    background-color: transparent;
  }

  div.icon-text {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
  }

  div.value-text {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;

    & > span {
      color: #565b60;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  input {
    padding: 10px 16px;
    font-family: 'Inter';
    font-size: 14px;
    color: #25292c;
    font-weight: 400;
    border-radius: 4px;
    border: 1px solid #b7bdc6;
    background: #fff;

    &:disabled {
      background: #f9f9f9;
      color: #b7bdc6;
    }
  }

  label {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
  }

  @media (max-width: 980px) {
    max-width: 100%;
  }
`

export const InputWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`
