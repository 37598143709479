export const gicsDataSet = (trans) => {
  return {
    options: [
      {
        value: '10',
        label: trans.section10,
        children: [
          {
            value: '1010',
            label: trans.section1010,
            children: [
              {
                value: '101010',
                label: trans.section101010,
              },
              {
                value: '101020',
                label: trans.section101020,
              },
            ],
          },
          {
            value: '1020',
            label: trans.section1020,
            children: [
              {
                value: '102010',
                label: trans.section102010,
              },
              {
                value: '102011',
                label: trans.section102011,
              },
              {
                value: '102012',
                label: trans.section102012,
              },
              {
                value: '102013',
                label: trans.section102013,
              },
              {
                value: '102014',
                label: trans.section102014,
              },
              {
                value: '102015',
                label: trans.section102015,
              },
              {
                value: '102016',
                label: trans.section102016,
              },
              {
                value: '102017',
                label: trans.section102017,
              },
              {
                value: '102018',
                label: trans.section102018,
              },
              {
                value: '102019',
                label: trans.section102019,
              },
              {
                value: '102020',
                label: trans.section102020,
              },
              {
                value: '102021',
                label: trans.section102021,
              },
              {
                value: '102022',
                label: trans.section102022,
              },
              {
                value: '102023',
                label: trans.section102023,
              },
              {
                value: '102024',
                label: trans.section102024,
              },
              {
                value: '102025',
                label: trans.section102025,
              },
              {
                value: '102026',
                label: trans.section102026,
              },
            ],
          },
        ],
      },
      {
        value: '20',
        label: trans.section20,
        children: [
          {
            value: '2010',
            label: trans.section2010,
            children: [
              {
                value: '201010',
                label: trans.section201010,
              },
              {
                value: '201011',
                label: trans.section201011,
              },
              {
                value: '201012',
                label: trans.section201012,
              },
              {
                value: '201013',
                label: trans.section201013,
              },
              {
                value: '201014',
                label: trans.section201014,
              },
            ],
          },
          {
            value: '2020',
            label: trans.section2020,
            children: [
              {
                value: '202010',
                label: trans.section202010,
              },
              {
                value: '202011',
                label: trans.section202011,
              },
              {
                value: '202012',
                label: trans.section202012,
              },
              {
                value: '202013',
                label: trans.section202013,
              },
              {
                value: '202014',
                label: trans.section202014,
              },
              {
                value: '202015',
                label: trans.section202015,
              },
              {
                value: '202016',
                label: trans.section202016,
              },
              {
                value: '202017',
                label: trans.section202017,
              },
              {
                value: '202018',
                label: trans.section202018,
              },
              {
                value: '202019',
                label: trans.section202019,
              },
              {
                value: '202020',
                label: trans.section202020,
              },
              {
                value: '202021',
                label: trans.section202021,
              },
              {
                value: '202022',
                label: trans.section202022,
              },
              {
                value: '202023',
                label: trans.section202023,
              },
              {
                value: '202024',
                label: trans.section202024,
              },
              {
                value: '202025',
                label: trans.section202025,
              },
              {
                value: '202026',
                label: trans.section202026,
              },
              {
                value: '202027',
                label: trans.section202027,
              },
              {
                value: '202028',
                label: trans.section202028,
              },
              {
                value: '202029',
                label: trans.section202029,
              },
              {
                value: '202030',
                label: trans.section202030,
              },
            ],
          },
          {
            value: '2030',
            label: trans.section2030,
            children: [
              {
                value: '203010',
                label: trans.section203010,
              },
              {
                value: '203011',
                label: trans.section203011,
              },
              {
                value: '203012',
                label: trans.section203012,
              },
              {
                value: '203013',
                label: trans.section203013,
              },
              {
                value: '203014',
                label: trans.section203014,
              },
            ],
          },
        ],
      },
      {
        value: '30',
        label: trans.section30,
        children: [
          {
            value: '3010',
            label: trans.section3010,
            children: [
              {
                value: '301010',
                label: trans.section301010,
              },
            ],
          },
          {
            value: '3020',
            label: trans.section3020,
            children: [
              {
                value: '302010',
                label: trans.section302010,
              },
              {
                value: '302011',
                label: trans.section302011,
              },
              {
                value: '302012',
                label: trans.section302012,
              },
              {
                value: '302013',
                label: trans.section302013,
              },
              {
                value: '302014',
                label: trans.section302014,
              },
              {
                value: '302015',
                label: trans.section302015,
              },
              {
                value: '302016',
                label: trans.section302016,
              },
              {
                value: '302017',
                label: trans.section302017,
              },
            ],
          },
        ],
      },
      {
        value: '40',
        label: trans.section40,
        children: [
          {
            value: '4010',
            label: trans.section4010,
            children: [
              {
                value: '401010',
                label: trans.section401010,
              },
              {
                value: '401011',
                label: trans.section401011,
              },
              {
                value: '401012',
                label: trans.section401012,
              },
              {
                value: '401013',
                label: trans.section401013,
              },
              {
                value: '401014',
                label: trans.section401014,
              },
              {
                value: '401015',
                label: trans.section401015,
              },
              {
                value: '401016',
                label: trans.section401016,
              },
              {
                value: '401017',
                label: trans.section401017,
              },
              {
                value: '401018',
                label: trans.section401018,
              },
              {
                value: '401019',
                label: trans.section401019,
              },
              {
                value: '401020',
                label: trans.section401020,
              },
              {
                value: '401021',
                label: trans.section401021,
              },
            ],
          },
          {
            value: '4020',
            label: trans.section4020,
            children: [
              {
                value: '402010',
                label: trans.section402010,
              },
              {
                value: '402011',
                label: trans.section402011,
              },
              {
                value: '402012',
                label: trans.section402012,
              },
              {
                value: '402013',
                label: trans.section402013,
              },
              {
                value: '402014',
                label: trans.section402014,
              },
              {
                value: '402015',
                label: trans.section402015,
              },
              {
                value: '402016',
                label: trans.section402016,
              },
              {
                value: '402017',
                label: trans.section402017,
              },
              {
                value: '402018',
                label: trans.section402018,
              },
              {
                value: '402019',
                label: trans.section402019,
              },
              {
                value: '402020',
                label: trans.section402020,
              },
            ],
          },
          {
            value: '4030',
            label: trans.section4030,
            children: [
              {
                value: '403010',
                label: trans.section403010,
              },
              { value: '403011', label: trans.section403011 },
              { value: '403012', label: trans.section403012 },
              { value: '403013', label: trans.section403013 },
              { value: '403014', label: trans.section403014 },
              { value: '403015', label: trans.section403015 },
              {
                value: '403016',
                label: trans.section403016,
              },
              { value: '403017', label: trans.section403017 },
              {
                value: '403018',
                label: trans.section403018,
              },
              { value: '403019', label: trans.section403019 },
              {
                value: '403020',
                label: trans.section403020,
              },
              {
                value: '403021',
                label: trans.section403021,
              },
              { value: '403022', label: trans.section403022 },
            ],
          },
          {
            value: '4040',
            label: trans.section4040,
            children: [
              {
                value: '404010',
                label: trans.section404010,
              },
              {
                value: '404011',
                label: trans.section404011,
              },
              {
                value: '404012',
                label: trans.section404012,
              },
              {
                value: '404013',
                label: trans.section404013,
              },
              { value: '404014', label: trans.section404014 },
              { value: '404015', label: trans.section404015 },
              { value: '404016', label: trans.section404016 },
              {
                value: '404017',
                label: trans.section404017,
              },
              { value: '404018', label: trans.section404018 },
              {
                value: '404019',
                label: trans.section404019,
              },
              {
                value: '404020',
                label: trans.section404020,
              },
              { value: '404021', label: trans.section404021 },
            ],
          },
          {
            value: '4050',
            label: trans.section4050,
            children: [
              {
                value: '405010',
                label: trans.section405010,
              },
              { value: '405011', label: trans.section405011 },
              {
                value: '405012',
                label: trans.section405012,
              },
              {
                value: '405013',
                label: trans.section405013,
              },
              { value: '405014', label: trans.section405014 },
              {
                value: '405015',
                label: trans.section405015,
              },
              { value: '405016', label: trans.section405016 },
              { value: '405017', label: trans.section405017 },
              { value: '405018', label: trans.section405018 },
              { value: '405019', label: trans.section405019 },
              {
                value: '405020',
                label: trans.section405020,
              },
              {
                value: '405021',
                label: trans.section405021,
              },
            ],
          },
          {
            value: '4060',
            label: trans.section4060,
            children: [
              {
                value: '406010',
                label: trans.section406010,
              },
              {
                value: '406011',
                label: trans.section406011,
              },
              { value: '406012', label: trans.section406012 },
              { value: '406013', label: trans.section406013 },
              {
                value: '406014',
                label: trans.section406014,
              },
              { value: '406015', label: trans.section406015 },
              {
                value: '406016',
                label: trans.section406016,
              },
              { value: '406017', label: trans.section406017 },
              {
                value: '406018',
                label: trans.section406018,
              },
              { value: '406019', label: trans.section406019 },
              { value: '406020', label: trans.section406020 },
              { value: '406021', label: trans.section406021 },
              {
                value: '406022',
                label: trans.section406022,
              },
              { value: '406023', label: trans.section406023 },
              { value: '406024', label: trans.section406024 },
              { value: '406025', label: trans.section406025 },
              { value: '406026', label: trans.section406026 },
            ],
          },
        ],
      },

      {
        value: '50',
        label: trans.section50,
        children: [
          {
            value: '5010',
            label: trans.section5010,
            children: [
              { value: '501010', label: trans.section501010 },
              { value: '501011', label: trans.section501011 },
              { value: '501012', label: trans.section501012 },
              {
                value: '501013',
                label: trans.section501013,
              },
              {
                value: '501014',
                label: trans.section501014,
              },
              { value: '501015', label: trans.section501015 },
              {
                value: '501016',
                label: trans.section501016,
              },
              { value: '501017', label: trans.section501017 },
              { value: '501018', label: trans.section501018 },
            ],
          },
          {
            value: '5020',
            label: trans.section5020,
            children: [
              { value: '502010', label: trans.section502010 },
              {
                value: '502011',
                label: trans.section502011,
              },
              {
                value: '502012',
                label: trans.section502012,
              },
              { value: '502013', label: trans.section502013 },
              {
                value: '502014',
                label: trans.section502014,
              },
              { value: '502015', label: trans.section502015 },
              {
                value: '502016',
                label: trans.section502016,
              },
            ],
          },
          {
            value: '5030',
            label: trans.section5030,
            children: [
              { value: '503010', label: trans.section503010 },
              { value: '503011', label: trans.section503011 },
              { value: '503012', label: trans.section503012 },
              {
                value: '503013',
                label: trans.section503013,
              },
              {
                value: '503014',
                label: trans.section503014,
              },
              {
                value: '503015',
                label: trans.section503015,
              },
              {
                value: '503016',
                label: trans.section503016,
              },
              {
                value: '503017',
                label: trans.section503017,
              },
              {
                value: '503018',
                label: trans.section503018,
              },
              { value: '503019', label: trans.section503019 },
              { value: '503020', label: trans.section503020 },
            ],
          },
          {
            value: '5040',
            label: trans.section5040,
            children: [
              { value: '504010', label: trans.section504010 },
              {
                value: '504011',
                label: trans.section504011,
              },
              { value: '504012', label: trans.section504012 },
              { value: '504013', label: trans.section504013 },
              { value: '504014', label: trans.section504014 },
              {
                value: '504015',
                label: trans.section504015,
              },
              {
                value: '504016',
                label: trans.section504016,
              },
              { value: '504017', label: trans.section504017 },
              {
                value: '504018',
                label: trans.section504018,
              },
              { value: '504019', label: trans.section504019 },
            ],
          },
          {
            value: '5050',
            label: trans.section5050,
            children: [
              {
                value: '505010',
                label: trans.section505010,
              },
              {
                value: '505011',
                label: trans.section505011,
              },
              { value: '505012', label: trans.section505012 },
              { value: '505013', label: trans.section505013 },
              {
                value: '505014',
                label: trans.section505014,
              },
              { value: '505015', label: trans.section505015 },
              {
                value: '505016',
                label: trans.section505016,
              },
              { value: '505017', label: trans.section505017 },
              {
                value: '505018',
                label: trans.section505018,
              },
              {
                value: '505019',
                label: trans.section505019,
              },
              { value: '505020', label: trans.section505020 },
              {
                value: '505021',
                label: trans.section505021,
              },
              { value: '505022', label: trans.section505022 },
              { value: '505023', label: trans.section505023 },
            ],
          },
          {
            value: '5060',
            label: trans.section5060,
            children: [
              { value: '506010', label: trans.section506010 },
              {
                value: '506011',
                label: trans.section506011,
              },
              {
                value: '506012',
                label: trans.section506012,
              },
              {
                value: '506013',
                label: trans.section506013,
              },
              {
                value: '506014',
                label: trans.section506014,
              },
              {
                value: '506015',
                label: trans.section506015,
              },
              {
                value: '506016',
                label: trans.section506016,
              },
              {
                value: '506017',
                label: trans.section506017,
              },
              { value: '506018', label: trans.section506018 },
              { value: '506019', label: trans.section506019 },
              {
                value: '506020',
                label: trans.section506020,
              },
              {
                value: '506021',
                label: trans.section506021,
              },
              {
                value: '506022',
                label: trans.section506022,
              },
              {
                value: '506023',
                label: trans.section506023,
              },
              { value: '506024', label: trans.section506024 },
              {
                value: '506025',
                label: trans.section506025,
              },
              {
                value: '506026',
                label: trans.section506026,
              },
              {
                value: '506027',
                label: trans.section506027,
              },
              { value: '506028', label: trans.section506028 },
              {
                value: '506029',
                label: trans.section506029,
              },
              {
                value: '506030',
                label: trans.section506030,
              },
            ],
          },
          {
            value: '5070',
            label: trans.section5070,
            children: [
              {
                value: '507010',
                label: trans.section507010,
              },
              {
                value: '507011',
                label: trans.section507011,
              },
              {
                value: '507012',
                label: trans.section507012,
              },
              { value: '507013', label: trans.section507013 },
              {
                value: '507014',
                label: trans.section507014,
              },
              {
                value: '507015',
                label: trans.section507015,
              },
              {
                value: '507016',
                label: trans.section507016,
              },
              { value: '507017', label: trans.section507017 },
            ],
          },
          {
            value: '5080',
            label: trans.section5080,
            children: [
              {
                value: '508010',
                label: trans.section508010,
              },
              { value: '508011', label: trans.section508011 },
              { value: '508012', label: trans.section508012 },
              {
                value: '508013',
                label: trans.section508013,
              },
              {
                value: '508014',
                label: trans.section508014,
              },
              { value: '508015', label: trans.section508015 },
              {
                value: '508016',
                label: trans.section508016,
              },
              { value: '508017', label: trans.section508017 },
              { value: '508018', label: trans.section508018 },
              {
                value: '508019',
                label: trans.section508019,
              },
              {
                value: '508020',
                label: trans.section508020,
              },
            ],
          },
        ],
      },

      {
        value: '60',
        label: trans.section60,
        children: [
          {
            value: '6010',
            label: trans.section6010,
            children: [
              { value: '601010', label: trans.section601010 },
              {
                value: '601011',
                label: trans.section601011,
              },
              {
                value: '601012',
                label: trans.section601012,
              },
              { value: '601013', label: trans.section601013 },
              {
                value: '601014',
                label: trans.section601014,
              },
              {
                value: '601015',
                label: trans.section601015,
              },
              {
                value: '601016',
                label: trans.section601016,
              },
              { value: '601017', label: trans.section601017 },
              { value: '601018', label: trans.section601018 },
              {
                value: '601019',
                label: trans.section601019,
              },
              { value: '601020', label: trans.section601020 },
              {
                value: '601021',
                label: trans.section601021,
              },
              { value: '601022', label: trans.section601022 },
              {
                value: '601023',
                label: trans.section601023,
              },
              {
                value: '601024',
                label: trans.section601024,
              },
              { value: '601025', label: trans.section601025 },
              {
                value: '601026',
                label: trans.section601026,
              },
              {
                value: '601027',
                label: trans.section601027,
              },
            ],
          },
          {
            value: '6020',
            label: trans.section6020,
            children: [
              {
                value: '602010',
                label: trans.section602010,
              },
              {
                value: '602011',
                label: trans.section602011,
              },
              {
                value: '602012',
                label: trans.section602012,
              },
              {
                value: '602013',
                label: trans.section602013,
              },
              { value: '602014', label: trans.section602014 },
              { value: '602015', label: trans.section602015 },
              {
                value: '602016',
                label: trans.section602016,
              },
              {
                value: '602017',
                label: trans.section602017,
              },
              { value: '602018', label: trans.section602018 },
              {
                value: '602019',
                label: trans.section602019,
              },
              { value: '602020', label: trans.section602020 },
              { value: '602021', label: trans.section602021 },
            ],
          },
        ],
      },
    ],
  }
}
