import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from '../../../components/App'
import { PageHeading } from '../../../components/PageHeading/PageHeading'
import { PersonalData } from './summary/PersonalData'
import { ToRight } from '../EnrollCompany.styles'
import {
  Boxed,
  Container,
  ProceedButton,
  AbsoluteStatusBadge,
  Content,
  FormSection,
} from './enroll.styles'
import { CompanyData } from './summary/CompanyData'
import { Directors } from './summary/Directors'
import { Ubos } from './summary/Ubos'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { StatusInfo } from '../../../components/StatusInfo/StatusInfo'

const statusMapping = {
  pending: 'notice',
  errored: 'negative',
  completed: 'positive',
}

export const Summary = observer(() => {
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const { language } = store.TranslationsState
  const {
    enrollCompanyPage: { summary: trans },
  } = store.TranslationsState.translations
  const [status, setStatus] = useState({ value: '', label: '' })
  const [isPolling, setIsPolling] = useState(true)

  const getStatus = async () => {
    try {
      const res = await axios.get(
        `${process.env.WEB_API_URL}/merchant-enrolment/merchants/status`,
        { withCredentials: true }
      )

      if (res.data && res.data.status) {
        setStatus({ value: res.data.status, label: trans[res.data.status] })

        if (res.data.status === 'completed') {
          setIsPolling(false)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleProceed = () => {
    navigate('/enroll-company/success')
  }

  useEffect(() => {
    getStatus()
  }, [])

  useEffect(() => {
    if (isPolling) {
      const intervalId = setInterval(getStatus, 30000) // Poll every 30 seconds

      // Cleanup on unmount or when stopping polling
      return () => clearInterval(intervalId)
    }
  }, [isPolling])

  return (
    <Container>
      <PageHeading
        title={trans.title}
        subtitle={trans.subtitle}
        margin="0 0 0 40px"
        marginMobile="0 0 0 80px"
      >
        {status.value && (
          <AbsoluteStatusBadge>
            <StatusInfo
              status={statusMapping[status.value]}
              top="20px"
              right="0"
            >
              {status.label}
            </StatusInfo>
          </AbsoluteStatusBadge>
        )}
      </PageHeading>
      <Content>
        <Boxed>
          <FormSection>
            <PageHeading
              title={trans.personalData}
              {...theme.pageHeading}
              headerFontSize="18px"
              margin="0"
              height="auto"
              border="0"
              position="relative"
              zIndex="0"
            />
            <PersonalData initialEdit={false} />
          </FormSection>

          <FormSection>
            <PageHeading
              title={trans.companyData}
              {...theme.pageHeading}
              headerFontSize="18px"
              margin="0"
              height="auto"
              border="0"
              position="relative"
              zIndex="0"
            />
            <CompanyData initialEdit={false} />
          </FormSection>

          <FormSection>
            <PageHeading
              title={trans.directors}
              {...theme.pageHeading}
              headerFontSize="18px"
              margin="0"
              height="auto"
              border="0"
              position="relative"
              zIndex="0"
            />
            <Directors initialEdit={false} />
          </FormSection>

          <FormSection>
            <PageHeading
              title={trans.ubos}
              {...theme.pageHeading}
              headerFontSize="18px"
              margin="0"
              height="auto"
              border="0"
              position="relative"
              zIndex="0"
            />
            <Ubos initialEdit={false} />
          </FormSection>

          {/* <ToRight>
          <ProceedButton onClick={handleProceed}>
            <span>{trans.continue}</span>
          </ProceedButton>
        </ToRight> */}
        </Boxed>
      </Content>
    </Container>
  )
})
