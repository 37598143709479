import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { PageHeading } from '../../../components/PageHeading/PageHeading'
import { StoreContext } from '../../../components/App'
import {
  AddNewUbosContainer,
  Boxed,
  Container,
  Button,
  ProceedButton,
  Content,
  ContentSection,
} from './enroll.styles'
import IconPlus from '../../../assets/icon_plus_blue.svg'
import { AssignActorInput } from '../../../components/AssignActorInput/AssignActorInput'
import { infoOnlyRequiredFields } from './Directors'
import {
  addAssignFunction,
  addPersonFunction,
  changePersonFunction,
  detelePersonFunction,
} from '../../../methods/actorFunctions'
import { Actor, blankRequest, Request } from './Actor.interface'
import { PersonCard } from '../../../components/PersonCard/PersonCard'
import { PersonCardAdd } from '../../../components/PersonCard/PersonCardAdd'
import { ToRight } from '../EnrollCompany.styles'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner'

let nextId = 0

export const Ubos = observer(() => {
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const {
    enrollCompanyPage: { ubos: trans },
  } = store.TranslationsState.translations

  const [isLoading, setIsLoading] = useState(true)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isProceedDisabled, setIsProceedDisabled] = useState(false)
  const [people, setPeople] = useState<Actor[]>([])
  const [saveRequest, setSaveRequest] = useState<Request>(blankRequest)
  const [initialCheckboxesState, setInitialCheckboxesState] = useState<
    Record<string, boolean>
  >({})

  const addDirectorToUbosHandler = (ids: Record<string, boolean>) => {
    const newSaveRequest = addAssignFunction(
      ids,
      initialCheckboxesState,
      saveRequest
    )

    setSaveRequest(newSaveRequest)
  }

  const handleAddPerson = () => {
    const data = addPersonFunction(nextId++, false, true, people, saveRequest)

    setPeople(data.people)
    setSaveRequest(data.saveRequest)
  }

  const handleDeletePerson = (id: string) => {
    const data = detelePersonFunction(id, people, saveRequest)

    setPeople(data.people)
    setSaveRequest(data.saveRequest)
  }

  const handleChangePerson = (actor: Actor) => {
    const data = changePersonFunction(actor, people, saveRequest)

    setPeople(data.people)
    setSaveRequest(data.saveRequest)
  }

  const handleProceed = async () => {
    try {
      setIsDisabled(true)

      await axios.post(
        `${process.env.WEB_API_URL}/merchant-enrolment/actors/ubo`,
        {
          ...saveRequest,
          edit: saveRequest.edit.map((actor) => ({
            actorId: actor.actorId,
            info: actor.info,
          })),
          add: saveRequest.add.map((actor) => ({
            info: actor.info,
          })),
        },
        {
          withCredentials: true,
        }
      )

      setIsDisabled(false)
      navigate('/enroll-company/summary')
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        toast(e.response.data.message, { type: 'error' })
      }
      setIsDisabled(false)
      console.log(e)
    }
  }

  const getActors = async () => {
    try {
      setIsDisabled(true)
      const res = await axios.get(
        `${process.env.WEB_API_URL}/merchant-enrolment/actors`,
        { withCredentials: true }
      )

      if (res.data) {
        setPeople(res.data.actors)
        setInitialCheckboxesState(
          res.data.actors
            ?.filter((person) => person.isDirector)
            .reduce((acc, actor) => {
              acc[actor.actorId] = actor.isUbo
              return acc
            }, {} as Record<string, boolean>)
        )
      }

      setIsDisabled(false)
      setIsLoading(false)
    } catch (e) {
      setIsDisabled(false)
      setIsLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    getActors()
  }, [])

  useEffect(() => {
    setIsProceedDisabled(true)
    const isRequiredDataSet = people
      .filter((person) => person.isUbo)
      .every((person) =>
        Object.values(infoOnlyRequiredFields(person.info)).every(
          (x) => x !== null && x !== '' && x !== 0
        )
      )

    if (isRequiredDataSet) setIsProceedDisabled(false)
  }, [people])

  return (
    <Container>
      <PageHeading
        title={trans.title}
        subtitle={trans.subtitle}
        margin="0 0 0 40px"
        marginMobile="0 0 0 80px"
      />
      <Content>
        {isLoading ? (
          <ContentSection>
            <LoadingSpinner
              {...theme.loadingSpinner}
              height="278px"
              width="48px"
              bgColor="#fff"
              primary="#0071f3"
            />
          </ContentSection>
        ) : (
          <Boxed>
            {people
              .filter(
                (person) =>
                  !person.isApplicant &&
                  !person.isDirector &&
                  person.isUbo &&
                  !person.isNew
              )
              .map((person) => (
                <PersonCard
                  key={person.actorId}
                  person={person}
                  onChange={handleChangePerson}
                  onDelete={handleDeletePerson}
                  initialEdit={true}
                  isLoading={isDisabled}
                />
              ))}

            {people
              .filter((person) => person.isNew)
              .map((person) => (
                <PersonCardAdd
                  key={person.actorId}
                  person={person}
                  onChange={handleChangePerson}
                  onDelete={handleDeletePerson}
                  isLoading={isDisabled}
                />
              ))}

            <AssignActorInput
              title={trans.assignDirectorAsUbo}
              actors={people.filter((person) => person.isDirector)}
              initialCheckboxesState={initialCheckboxesState}
              onChange={addDirectorToUbosHandler}
            />

            <AddNewUbosContainer>
              <h5>{trans.addNewUbo}</h5>
              <p>{trans.clickHereToAddNewUbo}</p>
              <Button onClick={handleAddPerson} {...theme.button}>
                <img src={IconPlus} alt="Add" />
                {trans.addNewUbo}
              </Button>
            </AddNewUbosContainer>

            <ToRight>
              <ProceedButton
                onClick={handleProceed}
                disabled={isProceedDisabled}
                {...theme.proceedButton}
              >
                <span>{trans.proceed}</span>
              </ProceedButton>
            </ToRight>
          </Boxed>
        )}
      </Content>
    </Container>
  )
})
