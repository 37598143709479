import React from 'react'
import { Container } from './StatusInfo.styles'

interface StatusInfoProps {
  children: React.ReactNode
  status?: string
  top?: string
  right?: string
}

export const StatusInfo = ({
  children,
  status,
  top,
  right,
}: StatusInfoProps) => {
  const statuses = {
    negative: {
      bgColor: '#FFE9EB',
      bulletColor: '#D8513F',
      color: '#A82626',
    },
    notice: {
      bgColor: '#FFF0E2',
      bulletColor: '#FB9600',
      color: '#995403',
    },
    positive: {
      bgColor: '#E0FCF5',
      bulletColor: '#2B633F',
      color: '#2B633F',
    },
  }

  return (
    <Container
      bgColor={statuses[status].bgColor}
      color={statuses[status].color}
      top={top}
      right={right}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
      >
        <circle cx="5" cy="5" r="5" fill={statuses[status].bulletColor} />
      </svg>
      {children}
    </Container>
  )
}
