import React, { FC, PropsWithChildren } from 'react'
import { StyledTextInput } from './TextInput.styles'

type Props = {
  type: 'text' | 'url' | 'email' | 'date' | 'phone'
  defaultValue?: string
  className?: string
  value?: string
  name?: string
  disabled?: boolean
  required?: boolean
  readOnly?: boolean
  onChange?: (number) => void
  onBlur?: (number) => void
  placeholder?: string
  bgColor?: string
  padding?: string
  borderRadius?: string
  border?: string
  color?: string
  borderColorFocus?: string
}

export const TextInput: FC<PropsWithChildren<Props>> = (props) => {
  return (
    <StyledTextInput
      {...props}
      type={props.type || 'text'}
      className={props.className}
      defaultValue={props.defaultValue}
      value={props.value}
      name={props.name}
      disabled={!!props.disabled}
      required={!!props.required}
      readOnly={!!props.readOnly}
      placeholder={props.placeholder}
      bgColor={props.bgColor}
      padding={props.padding}
      borderRadius={props.borderRadius}
      border={props.border}
      color={props.color}
      borderColorFocus={props.borderColorFocus}
    >
      {props.children}
    </StyledTextInput>
  )
}
