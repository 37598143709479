import LogoAmex from '../assets/logo_amex.svg'

export const amexTheme = {
  logo: LogoAmex,
  companyName: 'American Express',
  titlePrefix: 'American Express - ',
  globals: {
    name: 'Amex',
    fontFamily: 'Inter',
    fontFamilyHeadline: 'Inter',
    bgColorCategory: 'rgba(247, 248, 249, 1)',
    linkColor: 'rgba(0, 111, 207, 1)',
    linkColorHover: 'rgba(16, 104, 165, 1)',
    checkboxColor: 'rgba(0, 111, 207)',
    checkboxColorActive: 'rgba(0, 111, 207)',
  },
  container: {
    bgColorCategory: '#FFFFFF',
    width: '100%',
    padding: '60px 80px 60px',
    borderRadius: '0px',
    border: 'none',
    boxShadow: 'none',
    margin: '0 auto',
    color: '#505050',
  },
  documentCheckbox: {
    iconColor: 'rgba(0, 111, 207, 1)',
    iconHover: 'rgba(0, 111, 207, 0.6)',
    width: '100%',
    contentFontSize: '14px',
    margin: '20px',
  },
  icon: {
    color: 'rgba(0, 111, 207, 1)',
    colorHover: 'rgba(0, 111, 207, 1)',
    size: '32px',
    thickness: '0.2px',
  },
  otpInput: {
    width: '100%',
    columnGap: '10px',
    marginTop: '10px',
    marginBottom: '30px',
    inputHeight: '60px',
    inputBorder: '1px solid rgba(200, 201, 199, 1)',
    borderRadius: '8px',
    fontSize: '28px',
    focusColor: 'rgba(0, 111, 207, 1)',
    bgColorCategory: 'rgba(247, 248, 249, 1)',
    focusBgColor: 'rgba(247, 248, 249, 1)',
    fontColor: '#000000',
    loadingColor: 'rgba(0, 111, 207, 1)',
  },
  pdfViewer: {
    pageBackground: 'rgba(255, 255, 255, 1)',
    canvasWidth: '495',
    containerPadding: '',
    containerBorder: '1px solid rgba(235, 235, 235, 1)',
    navigationTop: '',
    navigationLeft: '',
    navigationRight: '',
    navigationBottom: '',
    navigationBgColor: 'rgba(202, 202, 202, 0.4)',
    navigationBorderRadius: '8px',
    navigationPadding: '8px',
    navigationBoxShadow:
      'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;',
    navigationButtonBgColor: 'rgba(0, 111, 207, 1)',
    navigationButtonBorder: '1px solid rgba(0, 111, 207, 1)',
    navigationButtonColor: 'rgba(255, 255, 255, 1)',
    navigationButtonBgColorHover: 'rgba(0, 111, 207, 0.8)',
    navigationButtonBorderHover: '1px solid rgba(0, 111, 207, 0.8)',
    navigationButtonColorHover: 'rgba(255, 255, 255, 1)',
    navigationButtonPadding: '0px 5px',
    navigationButtonColorDisabled: 'rgba(255, 255, 255, 1)',
    navigationButtonBgColorDisabled: 'rgba(0, 111, 207, 1)',
    navigationButtonBorderDisabled: '1px solid rgba(0, 111, 207, 1)',
    navigationSpacerColor: 'rgba(180, 180, 180, 1)',
    searchTop: '',
    searchLeft: '',
    searchRight: '',
    searchBottom: '',
    searchBgColor: 'rgba(202, 202, 202, 0.4)',
    searchBorderRadius: '8px',
    searchPadding: '8px',
    searchBoxShadow:
      'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;',
    searchColor: 'rgba(0, 111, 207, 1)',
    searchFontSize: '',
    searchInputRadius: '8px',
    searchInputHeight: '',
    searchInputColor: '',
    searchInputBgColor: '',
    searchInputBgColorFocus: 'rgba(255, 255, 255, 1)',
    searchInputBorder: '1px solid rgba(210, 210, 210, 1)',
    searchInputFocusBorder: '1px solid rgba(210, 210, 210, 1)',
    searchInputFocusOutline: '1px solid rgba(0, 111, 207, 1)',
    searchInputPadding: '',
  },
  button: {
    bgColorCategory: '#fff',
    borderRadius: '4px',
    color: '#006FCF',
    width: 'fit-content',
    bgColorCategoryHover: '#f8f7fa',
  },
  proceedButton: {
    bgColorCategory: '#006FCF',
    padding: '10px 24px',
    borderRadius: '4px',
    width: 'auto',
    border: 'none',
    boxShadow: 'none',
    margin: '0',
    color: 'rgba(255, 255, 255, 1)',
    colorHover: 'rgba(255, 255, 255, 1)',
    bgColorCategoryHover: 'rgba(16, 104, 165, 1)',
    borderHover: 'none',
    bgColorCategoryDisabled: '#ACB0B5',
    borderDisabled: 'none',
    colorDisabled: 'rgba(255, 255, 255, 1)',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '600',
    fontFamily: 'Inter',
  },
  buttonDisabled: {
    bgColorCategory: 'rgba(0, 111, 207, 1)',
    padding: '10px 24px',
    borderRadius: '4px',
    width: 'auto',
    border: '1px solid rgba(0, 111, 207, 1)',
    boxShadow: 'none',
    margin: '0',
    color: 'rgba(255, 255, 255, 1)',
    colorHover: 'rgba(255, 255, 255, 1)',
    bgColorCategoryHover: 'rgba(0, 111, 207, 1)',
    borderHover: '1px solid rgba(0, 111, 207, 1)',
    fontSize: '13px',
    fontWeight: '600',
  },
  header: {
    color: '#505050',
    fontSize: '1.625rem',
    lineHeight: '2.25rem',
    fontSizeMobile: '1.25rem',
    lineHeightMobile: '1.9375rem',
    margin: '15px 0',
    textAlign: 'center',
    width: '100%',
  },
  symbol: {
    primary: 'rgba(0, 111, 207, 1)',
    secondary: 'rgba(255, 255, 255, 1)',
    tenary: 'rgba(247, 248, 249, 1)',
  },
  textInput: {
    bgColorCategory: 'rgba(247, 248, 249, 1)',
    padding: '14px',
    borderRadius: '4px',
    border: '1px solid rgba(200, 201, 199, 1)',
    boxShadow: 'none',
    margin: '0',
    color: 'black',
    colorFocus: 'black',
    bgColorCategoryFocus: 'rgba(247, 248, 249, 1)',
    borderFocus: '1px solid rgba(200, 201, 199, 1)',
    bgColorCategoryDisabled: 'silver',
    borderDisabled: 'gray',
    colorDisabled: 'white',
    fontSize: '14px',
    fontWeight: '400',
    outline: '1px solid rgba(0, 111, 207, 1)',
    errorColor: '#d91111',
  },
  selectInput: {
    borderRadius: '1px',
    borderWidth: '1px',
    border: '1px solid rgba(200, 201, 199, 1)',
    borderFocus: '1px solid rgba(200, 201, 199, 1)',
    outline: '1px solid rgba(0, 111, 207, 1)',
    borderColorFocus: 'rgba(28, 105, 212, 1)',
    backgroundColorSelected: 'rgba(28, 105, 212, 1)',
    backgroundColorFocus: 'rgba(208, 227, 255, 1)',
    indicatorColorFocus: 'black',
    indicatorColorHover: 'rgba(28, 105, 212, 1)',
    danger: 'rgba(226,64,40,1)',
    dangerLight: 'rgba(226,64,40,0.5)',
    fontSize: '14px',
  },
  brandBar: {
    bgColorCategory: 'white',
    padding: '30px',
    margin: '0 0 30px 0',
    border: 'none',
    boxShadow: 'none',
    justifyContent: 'normal',
  },
  wrapXs2a: {
    fontFamily: '"Open Sans", sans-serif',
    fontFamilyHeadline: '"Open Sans", sans-serif',
    bgColorCategory: '#FFFFFF',
    linkColor: 'rgba(0, 111, 207, 1)',
    linkColorHover: 'rgba(0, 111, 207, 1)',
    checkboxColor: 'rgba(0, 111, 207, 1)',
    checkboxColorActive: 'rgba(0, 111, 207, 1)',
    backButton: {
      borderColorCategory: 'rgba(247, 248, 249, 1)',
      color: 'black',
      colorHover: 'black',
      disabled: 'silver',
    },
    input: {
      bgColorCategory: 'rgba(247, 248, 249, 1)',
      padding: '14px',
      borderRadius: '4px',
      border: '1px solid rgba(200, 201, 199, 1)',
      boxShadow: 'none',
      margin: '0',
      color: 'black',
      colorFocus: 'black',
      bgColorCategoryFocus: 'rgba(247, 248, 249, 1)',
      borderFocus: '1px solid rgba(200, 201, 199, 1)',
      bgColorCategoryDisabled: 'silver',
      borderDisabled: 'gray',
      colorDisabled: 'white',
      fontSize: '14px',
      fontWeight: '400',
      outline: '1px solid rgba(0, 111, 207, 1)',
      errorColor: '#d91111',
    },
    proceedButton: {
      bgColorCategory: 'rgba(0, 111, 207, 1)',
      padding: '10px 24px',
      borderRadius: '4px',
      width: 'auto',
      border: '1px solid rgba(0, 111, 207, 1)',
      boxShadow: 'none',
      margin: '0',
      color: 'rgba(255, 255, 255, 1)',
      colorHover: 'rgba(255, 255, 255, 1)',
      bgColorCategoryHover: 'rgba(16, 104, 165, 1)',
      borderHover: '1px solid rgba(16, 104, 165, 0.6)',
      bgColorCategoryDisabled: 'rgba(0, 111, 207, 1)',
      borderDisabled: '1px solid rgba(0, 111, 207, 1)',
      colorDisabled: 'rgba(255, 255, 255, 1)',
      fontSize: '13px',
      fontWeight: '600',
    },
  },
  wrapKlarna: {
    bgColorCategory: 'rgba(235, 235, 235, 1)',
  },
  wrapKontomatik: {
    bgColorCategory: '#FFF',
    textColor: 'rgba(0, 0, 0, 1)',
    borderColorCategory: 'rgba(28, 105, 212, 1)',
    headerColor: '#505050',
    primaryColor: 'rgba(0, 101, 131, 1)',
    primaryReverseColor: '#FFF',
    secondaryColor: '#586374',
    mainHoverColor: 'rgb(16, 104, 165)',
    mainHoverReverseColor: '#FFF',
    listHoverColor: '#F5F5F5',
    disabledColor: '#CED4DA',
    errorColor: '#BB0000',
    width: '100%',
    padding: '60px 80px 60px',
    borderRadius: '0px',
    border: 'none',
    boxShadow: 'none',
    margin: '0 auto',
  },
  progressBar: {
    time: 180,
    secondChance: true,
    showCounter: true,
    height: '18px',
    margin: '40px 0 10px 0',
    padding: '0 20px',
    border: '1px solid rgba(220, 220, 220, 1)',
    borderRadius: '18px',
    background: 'white',
    barFill:
      'linear-gradient(90deg, rgba(0, 111, 207,0) 0%, rgba(0, 111, 207,1) 100%)',
    counterMargin: '20px 0',
    counterPadding: '0',
    counterFontSize: '24px',
    counterColor: 'rgba(0, 111, 207, 1)',
    infoMessageTextAlign: 'center',
    infoMessageFontSize: '12px',
    infoMessageMargin: '6px 0 0 0',
  },
  stepper: {
    borderColorCategory: 'rgba(200, 201, 199, 1)',
    fontSizeTitle: '12px',
    fontSizeDesc: '10px',
  },
  loadingSpinner: {
    primary: 'rgba(0, 111, 207, 1)',
    bgColorCategory: 'rgba(235, 235, 235, 1)',
  },
  tabSwitcher: {
    borderBottom: '2px solid #dbdbdb',
    buttonColor: '#9b9b9b',
    buttonBorderBottom: '4px solid rgba(0, 111, 207, 1)',
    buttonColorActive: '#000',
  },
  leftSideNav: {
    color: 'rgba(52, 53, 58, 1)',
    bgColorCategory: 'rgb(249,249,251)',
    border: '1px solid #E9E7EE',
    fontFamily: '"Inter',
  },
  navMenu: {
    color: '#272142',
    colorActive: '#7d51da',
    border: '6px solid #7d51da',
    margin: '24px 0 40px 0',
  },
  onboardingWizard: {
    colorCurrent: '#006FCF',
    // CATEGORY
    colorCategoryName: '#7F858B',
    colorCategoryNameActive: '#2B253A',
    // CATEGORY ORB
    colorCategoryOrb: '#C6C3C3',
    borderColorCategoryOrb: '#C6C3C3',
    bgColorCategoryOrb: '#F5F4F3',
    // CATEGORY ORB ACTIVE
    colorCategoryOrbActive: '#006FCF',
    borderColorCategoryOrbActive: '#006FCF',
    bgColorCategoryOrbActive: '#fff',
    // CATEGORY ORB DONE
    colorCategoryOrbDone: '#fff',
    borderColorCategoryOrbDone: '#006FCF',
    bgColorCategoryOrbDone: '#006FCF',
    // DIVIDER
    bgColorDivider: '#F5F4F3',
    borderColorDivider: '#C6C3C3',
    bgColorDividerDone: '#006FCF',
    // STEP
    colorStepName: '#7F858B',
    colorStepNameActive: '#006FCF',
    colorStepNameDone: '#2B253A',
    // STEP ORB
    colorStepOrb: '#767676',
    borderColorStepOrb: '#C6C3C3',
    bgColorStepOrb: '#F5F4F3',
    // STEP ORB ACTIVE
    colorStepOrbActive: '#2B253A',
    borderColorStepOrbActive: '#006FCF',
    bgColorStepOrbActive: '#fff',
    // STEP ORB DONE
    colorStepOrbDone: '#fff',
    borderColorStepOrbDone: '#006FCF',
    bgColorStepOrbDone: '#006FCF',
  },
  inputRadio: {
    onBgColor: '#006FCF',
    onBgColorHover: '#006FCF',
    onIconColor: '#fff',
    offBgColor: '#cdc7be',
    offBgColorHover: '#cdc7be',
    offIconColor: '#fff',
    onIcon: undefined,
    offIcon: undefined,
  },
}
