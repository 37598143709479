export const externalList = [
  {
    value: 'AUT',
    code: 'AT',
    prefix: '43',
    countryName: 'Österreich',
    label: 'Austria',
    icon: '🇦🇹',
  },
  {
    value: 'BEL',
    code: 'BE',
    prefix: '32',
    countryName: 'België',
    label: 'Belgium',
    icon: '🇧🇪',
  },
  {
    value: 'BGR',
    code: 'BG',
    prefix: '359',
    countryName: 'България',
    label: 'Bulgaria',
    icon: '🇧🇬',
  },
  {
    value: 'CYP',
    code: 'CY',
    prefix: '357',
    countryName: 'Κύπρος',
    label: 'Cyprus',
    icon: '🇨🇾',
  },
  {
    value: 'CZE',
    code: 'CZ',
    prefix: '420',
    countryName: 'Česko',
    label: 'Czechia',
    icon: '🇨🇿',
  },
  {
    value: 'DEU',
    code: 'DE',
    prefix: '49',
    countryName: 'Deutschland',
    label: 'Germany',
    icon: '🇩🇪',
  },
  {
    value: 'DNK',
    code: 'DK',
    prefix: '45',
    countryName: 'Danmark',
    label: 'Denmark',
    icon: '🇩🇰',
  },
  {
    value: 'EST',
    code: 'EE',
    prefix: '372',
    countryName: 'Eesti',
    label: 'Estonia',
    icon: '🇪🇪',
  },
  {
    value: 'ESP',
    code: 'ES',
    prefix: '34',
    countryName: 'España',
    label: 'Spain',
    icon: '🇪🇸',
  },
  {
    value: 'FIN',
    code: 'FI',
    prefix: '358',
    countryName: 'Suomi',
    label: 'Finland',
    icon: '🇫🇮',
  },
  {
    value: 'FRA',
    code: 'FR',
    prefix: '33',
    countryName: 'France',
    label: 'France',
    icon: '🇫🇷',
  },
  {
    value: 'GBR',
    code: 'GB',
    prefix: '44',
    countryName: 'United Kingdom',
    label: 'United Kingdom',
    icon: '🇬🇧',
  },
  {
    value: 'GRC',
    code: 'GR',
    prefix: '30',
    countryName: 'Ελλάς',
    label: 'Greece',
    icon: '🇬🇷',
  },
  {
    value: 'HRV',
    code: 'HR',
    prefix: '385',
    countryName: 'Hrvatska',
    label: 'Croatia',
    icon: '🇭🇷',
  },
  {
    value: 'HUN',
    code: 'HU',
    prefix: '36',
    countryName: 'Magyarország',
    label: 'Hungary',
    icon: '🇭🇺',
  },
  {
    value: 'IRL',
    code: 'IE',
    prefix: '353',
    countryName: 'Ireland',
    label: 'Ireland',
    icon: '🇮🇪',
  },
  {
    value: 'ISL',
    code: 'IS',
    prefix: '354',
    countryName: 'Ísland',
    label: 'Iceland',
    icon: '🇮🇸',
  },
  {
    value: 'ITA',
    code: 'IT',
    prefix: '39',
    countryName: 'Italia',
    label: 'Italy',
    icon: '🇮🇹',
  },
  {
    value: 'LIE',
    code: 'LI',
    prefix: '423',
    countryName: 'Liechtenstein',
    label: 'Liechtenstein',
    icon: '🇱🇮',
  },
  {
    value: 'LTU',
    code: 'LT',
    prefix: '370',
    countryName: 'Lietuva',
    label: 'Lithuania',
    icon: '🇱🇹',
  },
  {
    value: 'LUX',
    code: 'LU',
    prefix: '352',
    countryName: 'Lëtezebuerg',
    label: 'Luxembourg',
    icon: '🇱🇺',
  },
  {
    value: 'LVA',
    code: 'LV',
    prefix: '371',
    countryName: 'Latvija',
    label: 'Latvia',
    icon: '🇱🇻',
  },
  {
    value: 'MLT',
    code: 'MT',
    prefix: '356',
    countryName: 'Malta',
    label: 'Malta',
    icon: '🇲🇹',
  },
  {
    value: 'NLD',
    code: 'NL',
    prefix: '31',
    countryName: 'Nederland',
    label: 'The Netherlands',
    icon: '🇳🇱',
  },
  {
    value: 'NOR',
    code: 'NO',
    prefix: '37',
    countryName: 'Norge',
    label: 'Norway',
    icon: '🇳🇴',
  },
  {
    value: 'POL',
    code: 'PL',
    prefix: '48',
    countryName: 'Polska',
    label: 'Poland',
    icon: '🇵🇱',
  },
  {
    value: 'PRT',
    code: 'PT',
    prefix: '351',
    countryName: 'Portugal',
    label: 'Portugal',
    icon: '🇵🇹',
  },
  {
    value: 'ROU',
    code: 'RO',
    prefix: '40',
    countryName: 'România',
    label: 'Romania',
    icon: '🇷🇴',
  },
  {
    value: 'SWE',
    code: 'SE',
    prefix: '46',
    countryName: 'Sverige',
    label: 'Sweden',
    icon: '🇸🇪',
  },
  {
    value: 'SVN',
    code: 'SI',
    prefix: '386',
    countryName: 'Slovenija',
    label: 'Slovenia',
    icon: '🇸🇮',
  },
  {
    value: 'SVK',
    code: 'SK',
    prefix: '421',
    countryName: 'Slovensko',
    label: 'Slovakia',
    icon: '🇸🇰',
  },
]
