import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from '../../../components/App'

import { Link } from 'react-router-dom'
import { ToRight } from '../EnrollCompany.styles'

import { getPath } from '../../../methods/getPath'
import { PageHeading } from '../../../components/PageHeading/PageHeading'
import {
  Circle,
  Container,
  Content,
  ContentSection,
  ProceedButton,
} from './enroll.styles'
import CircleLogo from '../../../assets/identity_intro.svg'

export const IntroIdentity = observer(() => {
  const store = useContext(StoreContext)
  const { theme, themeName } = store.AppState
  const {
    enrollCompanyPage: { introIdentity: trans },
  } = store.TranslationsState.translations

  return (
    <Container>
      <PageHeading margin="0 0 0 40px" marginMobile="0 0 0 80px" />
      <Content>
        <ContentSection>
          <Circle margin="0">
            <img src={CircleLogo} />
          </Circle>
          <h3>{trans.welcome}</h3>
          <p>{trans.description.replace('COMPANY', theme.companyName)}</p>
        </ContentSection>

        <ToRight>
          <Link to={`/${getPath()}/identity-verification?themeId=${themeName}`}>
            <ProceedButton {...theme.proceedButton}>
              {trans.continue}
            </ProceedButton>
          </Link>
        </ToRight>
      </Content>
    </Container>
  )
})
