import styled, { css } from 'styled-components'

export const Container = styled.div<{
  isOpen?: boolean
  openedHeight?: number
}>`
  width: 100%;
  max-width: 503px;
  margin-bottom: 40px;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  box-sizing: border-box;

  border-radius: 8px;
  border: 1px solid #e9e7ee;
  background: #fff;

  transition: height 0.3s ease-in-out;
  height: ${({ isOpen, openedHeight }) =>
    isOpen ? `${openedHeight}px` : '76px'};

  overflow: hidden;

  @media (max-width: 980px) {
    max-width: 100%;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;

  color: #2b253a;
  font-family: Inter;
  font-size: var(--Fonts-Size-Headline-5, 18px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Fonts-Line-height-Headline-5, 26px); /* 144.444% */
`

export const DropdownCaret = styled.div<{ isOpen: boolean }>`
  transform: rotate(${({ isOpen }) => (!isOpen ? '180deg' : '0deg')});
  transition: transform 0.3s;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const InputRow = styled.div`
  border-radius: 8px;
  border: 1px solid #e9e7ee;
  background: #fff;
  padding: 24px 32px;
  display: flex;
  align-items: center;
  gap: 4px;
  user-select: none;
  cursor: pointer;

  transition: background 0.3s ease-in-out;

  &:hover {
    background: #f8f7fa;
  }
`

export const Title = styled.div`
  color: var(--Text-text-header-primary, #2b253a);
  font-family: Inter;
  font-size: var(--Fonts-Size-Headline-5, 18px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Fonts-Line-height-Headline-5, 26px); /* 144.444% */
`

export const Description = styled.div`
  color: var(--Text-text-secondary, #565b60);

  /* Paragraph 4 - Regular */
  font-family: Inter;
  font-size: var(--Fonts-Size-Paragraph-4, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Fonts-Line-height-Paragraph-4, 20px); /* 142.857% */
`

export const FakeCheckboxContainer = styled.div<{ checked?: boolean }>`
  width: 20px;
  height: 20px;
  position: relative;

  svg {
    position: absolute;
    transition: opacity 0.3s ease-out;

    ${({ checked }) =>
      !checked
        ? css`
            &:first-child {
              opacity: 0;
            }
          `
        : css`
            &:last-child {
              opacity: 0;
            }
          `}
  }
`
