import styled, { css } from 'styled-components'

interface ContainerProps {
  opened?: boolean
  width?: string
  color?: string
  bgColor?: string
  border?: string
  fontFamily?: string
  padding?: string
}

interface HeadingProps {
  color?: string
  border?: string
  height?: string
  bgColor?: string
}

interface CloseProps {
  top?: string
  right?: string
  left?: string
  bottom?: string
  cursor?: string
}

export const Container = styled.div<ContainerProps>`
  width: ${(props) => (props.width ? props.width : '264px')};
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 980px) {
    position: fixed;
    z-index: 100;
    transform: translateX(-90%);
    width: 100%;
    transition: all 0.5s ease-out;
    ${({ opened }) =>
      opened &&
      css`
        transform: translateX(0%);
        background-color: 'transparent';
      `};
  }
`

export const Heading = styled.div<HeadingProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 20px;
  box-sizing: border-box;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.5px;
  color: ${(props) => (props.color ? props.color : '#272142')};
  border-bottom: ${(props) =>
    props.border ? props.border : '1px solid rgb(234, 227, 248)'};
  border-right: ${(props) =>
    props.border ? props.border : '1px solid rgb(234, 227, 248)'};
  height: ${(props) => (props.height ? props.height : '94px')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#fff')};
  position: fixed;
  z-index: 2;
  left: 0;
  width: 300px;
`

export const Content = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-right: ${(props) => (props.border ? props.border : 'none')};
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'rgb(249, 249, 251)'};

  @media (max-width: 980px) {
    width: 85%;
  }
`

export const IconWrapper = styled.div.attrs({
  className: 'authflow-nammenu-icon-wrapper',
})<CloseProps>`
  display: none;
  position: absolute;
  top: ${(props) => (props.top ? props.top : '10px')};
  right: ${(props) => (props.right ? props.right : '10px')};
  cursor: ${(props) => (props.cursor ? props.cursor : 'pointer')};

  ${(props) => props.left && `left: ${props.left};`}
  ${(props) => props.bottom && `bottom: ${props.bottom};`}
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

  @media (max-width: 980px) {
    display: block;
  }
`
