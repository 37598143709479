import styled from 'styled-components'

interface ContainerProps {
  padding?: string
}

interface CategoryOrbProps {
  status?: string
  isCurrent?: boolean
  fontFamily?: string
  clickable?: boolean
  colorCurrent?: string
  // CATEGORY
  colorCategoryName?: string
  colorCategoryNameActive?: string
  colorCategoryNameDone?: string
  // CATEGORY ORB
  colorCategoryOrb?: string
  borderColorCategoryOrb?: string
  bgColorCategoryOrb?: string
  // CATEGORY ORB ACTIVE
  colorCategoryOrbActive?: string
  borderColorCategoryOrbActive?: string
  bgColorCategoryOrbActive?: string
  // CATEGORY ORB DONE
  colorCategoryOrbDone?: string
  borderColorCategoryOrbDone?: string
  bgColorCategoryOrbDone?: string
  // DIVIDER
  bgColorDivider?: string
  borderColorDivider?: string
  bgColorDividerDone?: string
  // STEP
  colorStepName?: string
  colorStepNameActive?: string
  colorStepNameDone?: string
  // STEP ORB
  colorStepOrb?: string
  borderColorStepOrb?: string
  bgColorStepOrb?: string
  // STEP ORB ACTIVE
  colorStepOrbActive?: string
  borderColorStepOrbActive?: string
  bgColorStepOrbActive?: string
  // STEP ORB DONE
  colorStepOrbDone?: string
  borderColorStepOrbDone?: string
  bgColorStepOrbDone?: string
}

const switchStepNameColor = (props) => {
  switch (props.status) {
    case 'active':
      return props.colorStepNameActive ? props.colorStepNameActive : '#5A338B'
    case 'done':
      return props.colorStepNameDone ? props.colorStepNameDone : '#767676'
    default:
      return props.colorStepName ? props.colorStepName : '#767676'
  }
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${(props) => (props.padding ? props.padding : '30px')};
  margin-top: 94px;
`

export const CategoryWrap = styled.div`
  gap: 0px;
  margin: 0 30px;
`

export const Category = styled.div`
  width: 100%;
  display: flex;
  margin: 4px 0;
  position: relative;
  align-items: center;
  gap: 15px;
`

export const CategoryOrbWrap = styled.div`
  width: 30px;
`

export const CategoryOrb = styled.div<CategoryOrbProps>`
  width: 26px;
  height: 26px;
  border: 2px solid
    ${(props) =>
      props.status === ''
        ? props.borderColorCategoryOrb
          ? props.borderColorCategoryOrb
          : '#C6C3C3'
        : props.status === 'active'
        ? props.borderColorCategoryOrbActive
          ? props.borderColorCategoryOrbActive
          : '#5A338B'
        : props.status === 'done'
        ? props.borderColorCategoryOrbDone
          ? props.borderColorCategoryOrbDone
          : '#5A338B'
        : props.borderColorCategoryOrbActive
        ? props.borderColorCategoryOrbActive
        : '#5A338B'};
  background-color: ${(props) =>
    props.status === ''
      ? props.bgColorCategoryOrb
        ? props.bgColorCategoryOrb
        : '#F5F4F3'
      : props.status === 'active'
      ? props.bgColorCategoryOrbActive
        ? props.bgColorCategoryOrbActive
        : '#fff'
      : props.status === 'done'
      ? props.bgColorCategoryOrbDone
        ? props.bgColorCategoryOrbDone
        : '#5A338B'
      : props.bgColorCategoryOrbActive
      ? props.bgColorCategoryOrbActive
      : '#fff'};
  color: ${(props) =>
    props.status === ''
      ? props.colorCategoryOrb
        ? props.colorCategoryOrb
        : '#767676'
      : props.status === 'active'
      ? props.colorCategoryOrbActive
        ? props.colorCategoryOrbActive
        : '#5A338B'
      : props.status === 'done'
      ? props.colorCategoryOrbDone
        ? props.colorCategoryOrbDone
        : '#fff'
      : props.colorCategoryOrbActive
      ? props.colorCategoryOrbActive
      : '#5A338B'};
  text-align: center;
  border-radius: 40px;
  line-height: 26px;
  font-weight: 600;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  position: relative;
  z-index: 1;
`

export const CategoryName = styled.div<CategoryOrbProps>`
  ${(props) => props.fontFamily || ''};
  line-height: 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: ${(props) => (props.isCurrent ? '600' : '400')};
  ${(props) =>
    props.isCurrent
      ? `
      color: ${props.colorCurrent ? props.colorCurrent : '#272142'};
    `
      : `
      color: ${
        props.status === ''
          ? props.colorCategoryName
            ? props.colorCategoryName
            : '#A19EAD'
          : props.colorCategoryNameActive
          ? props.colorCategoryNameActive
          : '#272142'
      };
  `}
  ${(props) =>
    !props.isCurrent &&
    props.clickable &&
    `
      cursor: pointer;
      &:hover {
        color: ${props.colorCurrent ? props.colorCurrent : '#272142'};
      }
    `}
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  position: relative;
  z-index: 1;
`

export const Steps = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const StepsDivider = styled.div<CategoryOrbProps>`
  height: 30px;
  width: 2px;
  border-radius: 3px;
  background-color: ${(props) =>
    props.status === ''
      ? props.bgColorDivider
        ? props.borderColorDivider
        : '#C6C3C3'
      : props.status === 'active'
      ? props.bgColorDividerDone
        ? props.bgColorDividerDone
        : '#5A338B'
      : props.status === 'done'
      ? props.bgColorDividerDone
        ? props.bgColorDividerDone
        : '#5A338B'
      : props.borderColorDivider
      ? props.borderColorDivider
      : '#C6C3C3'};
  margin: 4px 14px;
  position: relative;
  z-index: 1;
`

export const Step = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`

export const StepOrb = styled.div<CategoryOrbProps>`
  width: 10px;
  height: 10px;
  border: 2px solid
    ${(props) =>
      props.status === ''
        ? props.borderColorStepOrb
          ? props.borderColorStepOrb
          : '#C6C3C3'
        : props.status === 'active'
        ? props.borderColorStepOrbActive
          ? props.borderColorStepOrbActive
          : '#5A338B'
        : props.status === 'done'
        ? props.borderColorStepOrbDone
          ? props.borderColorStepOrbDone
          : '#5A338B'
        : props.borderColorStepOrbActive
        ? props.borderColorStepOrbActive
        : '#5A338B'};
  background-color: ${(props) =>
    props.status === ''
      ? props.bgColorStepOrb
        ? props.bgColorStepOrb
        : 'transparent'
      : props.status === 'active'
      ? props.bgColorStepOrbActive
        ? props.bgColorStepOrbActive
        : '#fff'
      : props.status === 'done'
      ? props.bgColorStepOrbDone
        ? props.bgColorStepOrbDone
        : '#5A338B'
      : props.bgColorStepOrbActive
      ? props.bgColorStepOrbActive
      : '#fff'};
  color: ${(props) =>
    props.status === ''
      ? props.color
        ? props.color
        : '#767676'
      : props.status === 'active'
      ? props.colorStepOrbActive
        ? props.colorStepOrbActive
        : '#5A338B'
      : props.status === 'done'
      ? props.colorStepOrbDone
        ? props.colorStepOrbDone
        : '#fff'
      : props.colorStepOrbActive
      ? props.colorStepOrbActive
      : '#5A338B'};
  border-radius: 16px;
  margin: 4px 24px 4px 8px;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  position: relative;
  z-index: 1;
`

export const StepName = styled.div<CategoryOrbProps>`
  font-size: 12px;
  line-height: 20px;
  font-weight: ${(props) => (props.isCurrent ? '600' : '400')};

  ${(props) =>
    props.isCurrent
      ? `
      color: ${props.colorCurrent ? props.colorCurrent : '#272142'};
    `
      : `
      color: ${switchStepNameColor(props)};
  `}
  ${(props) =>
    !props.isCurrent &&
    props.clickable &&
    `
      cursor: pointer;
      &:hover {
        color: ${props.colorCurrent ? props.colorCurrent : '#272142'};
      }
    `}
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  position: relative;
  z-index: 1;
`

export const Current = styled.div<{ isCategory?: boolean }>`
  background-color: #dfebf6;
  width: 250px;
  height: 60px;
  border-radius: 12px;
  position: absolute;
  z-index: 0;
  top: ${(props) => (props.isCategory ? '-15px' : '-20px')};
  left: -30px;
`
