import Select from 'react-select'
import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  top: 46px;
  right: 64px;
  z-index: 4;

  @media (max-width: 1024px) {
    top: 46px;
    right: 32px;
  }

  @media (max-width: 480px) {
    top: 8px;
    right: 8px;
  }
`

export const LanguageSelect = styled(Select).attrs({
  className: 'language-select',
  classNamePrefix: 'language-select',
})<{ $height?: string; $isClearButtonHidden?: boolean }>`
  width: 100%;
  height: 24px;
  background-color: transparent;
  border-radius: 0px;

  .language-select__clear-indicator {
    display: none;
  }

  .language-select__control {
    height: 24px;
    border: none;
    border-radius: 0px;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;

    &--menu-is-open {
      border: none;
      box-shadow: none;
    }

    &--is-focused {
      box-shadow: none;
    }

    &:hover {
      border: none;
      filter: brightness(1.1);
    }
  }

  .language-select__control--is-focused {
    border: none;
  }

  .language-select__indicator-separator {
    display: none;
  }

  .language-select__menu {
    padding: 0px;
  }

  .language-select__menu-list {
    padding: 0px;
  }

  .language-select__option {
    cursor: pointer;
    color: #0071f3;

    &.language-select__option--is-focused {
      background-color: #0071f3;
      color: #fff;
    }

    &.language-select__option--is-selected {
      background-color: #0071f3;
      color: #f5f5f5;
    }
  }

  .language-select__single-value {
    color: #0071f3;
  }

  .language-select__placeholder {
  }

  .language-select__indicator {
    padding: 0px;
  }

  .language-select__multi-value {
  }
`

export const Flag = styled.span`
  display: inline-block;
  width: 20px;
  margin-right: 10px;

  img {
    width: 100%;
    height: 100%;
  }
`

export const ItemInner = styled.div.attrs({
  className: 'authflow-country-select-item',
})`
  display: flex;
  align-items: center;
  width: 100%;

  font-family: Inter;
  font-size: var(--Fonts-Size-Paragraph-4, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Fonts-Line-height-Paragraph-4, 20px); /* 142.857% */
`
