import { SelectItem } from '../components/SimpleSelect/SimpleSelect'
import { gicsDataSet } from './gicsSorted'

export const gicsList = (trans): SelectItem[] => {
  return gicsDataSet(trans).options.reduce((acc, item) => {
    acc.push({ value: item.value, label: item.label })
    item.children.reduce((acc1, item1) => {
      acc1.push({ value: item1.value, label: item1.label })

      item1.children.reduce((acc2, item2) => {
        acc2.push({ value: item2.value, label: item2.label })
        return acc2
      }, acc1)

      return acc1
    }, acc)
    return acc
  }, [])
}
