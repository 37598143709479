import styled from 'styled-components'

interface ContainerProps {
  bgColor?: string
  color?: string
  right?: string
  top?: string
}

export const Container = styled.div.attrs({})<ContainerProps>`
  display: flex;
  padding: 6px 12px 6px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 29px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#2c60ff')};
  color: ${(props) => (props.color ? props.color : '#FFFFFF')};
  width: fit-content;
  position: absolute;
  right: ${(props) => (props.right ? props.right : '24px')};
  top: ${(props) => (props.top ? props.top : '24px')};
`
