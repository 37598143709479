import React from 'react'
import {
  Container,
  Header,
  Headings,
  Subtitle,
  Toolbox,
  ContainerProps,
  HeaderProps,
  SubtitleProps,
} from './PageHeading.styles'

interface PageHeadingProps {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  title?: string
  subtitle?: string
  children?: React.ReactNode
}

export const PageHeading = ({
  title,
  subtitle,
  children,
  margin,
  marginMobile,
  height,
  border,
  fontFamily,
  headerFontSize,
  headerLineHeight,
  headerColor,
  headerMargin,
  headerPadding,
  headerFontWeight,
  subtitleFontSize,
  subtitleLineHeight,
  subtitleColor,
  position,
  zIndex,
}: PageHeadingProps & ContainerProps & HeaderProps & SubtitleProps) => {
  return (
    <Container
      fontFamily={fontFamily}
      height={height}
      border={border}
      position={position}
      zIndex={zIndex}
    >
      <Headings margin={margin} marginMobile={marginMobile}>
        <Header
          headerFontSize={headerFontSize}
          headerLineHeight={headerLineHeight}
          headerColor={headerColor}
          headerMargin={headerMargin}
          headerPadding={headerPadding}
          headerFontWeight={headerFontWeight}
        >
          {title}
        </Header>
        <Subtitle
          subtitleFontSize={subtitleFontSize}
          subtitleLineHeight={subtitleLineHeight}
          subtitleColor={subtitleColor}
        >
          {subtitle}
        </Subtitle>
      </Headings>
      <Toolbox>{children}</Toolbox>
    </Container>
  )
}
