import styled from 'styled-components'
import { Cascader } from 'antd'

interface InputProps {
  bgColor?: string
  padding?: string
  borderRadius?: string
  border?: string
  color?: string
  borderColorFocus?: string
}

export const StyledCascader = styled(Cascader)<InputProps>`
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '5px'} !important;

  .ant-select-selector {
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : '5px'} !important;
    border: ${(props) =>
      props.border ? props.border : '1px solid #c6c3c3'} !important;
    background-color: inherit;
    color: ${(props) => (props.color ? props.color : '#1c1c1c')} !important;
    font-family: 'Inter';
    font-size: 14px !important;
  }

  &.ant-select-focused .ant-select-selector {
    border-color: ${(props) =>
      props.borderColorFocus ? props.borderColorFocus : '#0071F3'} !important;
    box-shadow: none !important;
    outline: 1px solid
      ${(props) =>
        props.borderColorFocus ? props.borderColorFocus : '#0071F3'} !important;
  }

  .ant-select-arrow {
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
  }
`
