import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from '../../../../components/App'
import { Boxed, Container, ProceedButton } from './../enroll.styles'
import { changePersonFunction } from '../../../../methods/actorFunctions'
import { Actor, blankRequest, Request } from '../Actor.interface'
import { PersonCard } from '../../../../components/PersonCard/PersonCard'
import { ToRight } from '../../EnrollCompany.styles'
import axios from 'axios'
import { toast } from 'react-toastify'

interface UbosProps {
  initialEdit: boolean
}

export const Ubos = observer(({ initialEdit }: UbosProps) => {
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const {
    enrollCompanyPage: { ubos: trans },
  } = store.TranslationsState.translations

  const [edit, setEdit] = useState(initialEdit)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isResendDisabled, setIsResendDisabled] = useState(false)
  const [isPolling, setIsPolling] = useState(true)

  const [people, setPeople] = useState<Actor[]>([])
  const [saveRequest, setSaveRequest] = useState<Request>(blankRequest)

  const handleChangePerson = (actor: Actor) => {
    const data = changePersonFunction(actor, people, saveRequest)

    setPeople(data.people)
    setSaveRequest(data.saveRequest)
  }

  const handleResendEmail = async (id: string) => {
    try {
      setIsResendDisabled(true)
      await axios.post(
        `${process.env.WEB_API_URL}/merchant-enrolment/resend-email`,
        { actorId: id },
        { withCredentials: true }
      )
      setIsResendDisabled(false)
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        toast(e.response.data.message, { type: 'error' })
      }
      setIsResendDisabled(false)
      console.log(e)
    }
  }

  const handleProceed = async () => {
    try {
      setIsDisabled(true)
      await axios.post(
        `${process.env.WEB_API_URL}/merchant-enrolment/actors/director`,
        {
          ...saveRequest,
          edit: saveRequest.edit.map((actor) => ({
            actorId: actor.actorId,
            info: actor.info,
          })),
          add: saveRequest.add.map((actor) => ({
            info: actor.info,
          })),
        },
        {
          withCredentials: true,
        }
      )

      setEdit(false)
      setIsDisabled(false)
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        toast(e.response.data.message, { type: 'error' })
      }
      setIsDisabled(false)
      console.log(e)
    }
  }

  const getActors = async () => {
    try {
      setIsDisabled(true)
      const res = await axios.get(
        `${process.env.WEB_API_URL}/merchant-enrolment/actors`,
        { withCredentials: true }
      )

      if (res.data) {
        setPeople(
          res.data.actors.sort((a, b) =>
            a.actorId.localeCompare(b.actorId)
          ) as Actor[]
        )

        if (
          res.data.actors
            .filter((actor: Actor) => !actor.isApplicant && actor.isDirector)
            .every((actor: Actor) => actor.isVerified)
        ) {
          setIsPolling(false)
        }
      }

      setIsDisabled(false)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getActors()
  }, [])

  useEffect(() => {
    if (isPolling) {
      const intervalId = setInterval(getActors, 30000) // Poll every 30 seconds

      // Cleanup on unmount or when stopping polling
      return () => clearInterval(intervalId)
    }
  }, [isPolling])

  return (
    <Container>
      <Boxed>
        {people
          .filter((person) => person.isUbo)
          .map((person) => (
            <PersonCard
              key={person.actorId}
              person={person}
              onChange={handleChangePerson}
              onEdit={() => setEdit(true)}
              initialEdit={edit}
              isLoading={isDisabled}
              resendEmail={!person.isDirector ? handleResendEmail : null}
              isResending={isResendDisabled}
            />
          ))}

        {edit && (
          <ToRight>
            <ProceedButton
              margin="0 0 40px 0"
              onClick={handleProceed}
              disabled={isDisabled}
              {...theme.proceedButton}
            >
              <span>{trans.save}</span>
            </ProceedButton>
          </ToRight>
        )}
      </Boxed>
    </Container>
  )
})
